@tailwind base;
@tailwind components;
@tailwind utilities;

.bgimage {
  background-image: url('./assets/sky.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: brightness(100%);
}

@layer base {
  h5 {
    @apply absolute px-6 duration-200 w-52 bottom-4 md:bottom-8 md:px-10 group-hover:scale-110 group-hover:text-black;
  }
}
/* since buton is a COMPONENT */
@layer components {
  .btn {
    @apply text-black border-2 border-black py-2 px-10 my-0 text-center tracking-widest uppercase font-bold font-alata  hover:bg-black hover:text-white;
  }
}
.item-gradient {
  @apply absolute top-0 bottom-0 right-0 left-0 bg-gradient-to-b from-transparent to-gray-900 group-hover:from-gray-50 group-hover:to-white group-hover:opacity-70;
}
.item-container {
  @apply flex flex-col justify-between w-full space-y-6 text-2xl text-white uppercase md:flex-row md:space-y-0 md:space-x-8;
}
.item {
  @apply relative overflow-hidden md:w-1/4;
}

.ficon:hover {
  filter: invert(79%) sepia(61%) saturate(464%) hue-rotate(130deg)
    brightness(89%) contrast(85%);
}

/* hamburger menu */
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all, 0.25s;
  position: relative;
}
.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  width: 24px;
  height: 2px;
  top: 0;
  left: 0;
  background: #fff;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}
.open {
  transform: rotate(90deg);
}
.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translateX(6px);
}
.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
}
